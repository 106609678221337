import React, { useEffect, useRef, useState } from "react";
import { testimonialData } from "../../helper/config";
import { ReactComponent as Global } from "../../assets/icons/map.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";

const TestimonialComponent = ({ loading, data }) => {
  const sliderRef = useRef(null);
  const [clonedData, setClonedData] = useState([]);
  const [cardWidth, setCardWidth] = useState(0);
  const autoplayRef = useRef(null);

  useEffect(() => {
    if (data) {
      setClonedData([...data, ...data, ...data]);
    }
  }, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (sliderRef.current) {
        const firstCard = sliderRef.current.querySelector(".testimonial-card");
        if (firstCard) {
          setCardWidth(firstCard.offsetWidth);
        }
      }
    }, 100); // Delay of 100ms

    return () => clearTimeout(timeout);
  }, [window.innerWidth]);

  const scrollLeft = () => {
    if (sliderRef.current && cardWidth > 0) {
      sliderRef.current.scrollBy({
        left: -(cardWidth + 16),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current && cardWidth > 0) {
      sliderRef.current.scrollBy({
        left: cardWidth + 16,
        behavior: "smooth",
      });
    }
  };

  const startAutoplay = () => {
    autoplayRef.current = setInterval(() => {
      if (sliderRef.current && cardWidth > 0) {
        const maxScrollLeft = sliderRef.current.scrollWidth / 2;
        if (sliderRef.current.scrollLeft >= maxScrollLeft) {
          sliderRef.current.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          setClonedData((prevData) => [...prevData, ...data]);
          sliderRef.current.scrollBy({
            left: cardWidth + 16,
            behavior: "smooth",
          });
        }
      }
    }, 3000);
  };

  const stopAutoplay = () => {
    if (autoplayRef.current) {
      clearInterval(autoplayRef.current);
    }
  };

  useEffect(() => {
    startAutoplay();
    return () => stopAutoplay(); // Clean up on unmount
  }, [cardWidth]);

  if (!data) {
    return null;
  }

  return (
    <div className="testimonial-div">
      <div className="about-info-div">
        <div className="common-label">Testimonial</div>
        <p className="about-desc-text">Read what people have to say about us</p>
      </div>
      <div className="display-column-gap-20">
        <div className="testimonial-card-div" ref={sliderRef}>
          {clonedData?.map((item, index) => {
            return (
              <div
                key={index}
                className="testimonial-card"
                onMouseEnter={stopAutoplay} // Stop autoplay on hover
                onMouseLeave={startAutoplay} // Resume autoplay when hover ends
              >
                {item?.city && (
                  <div className="user-country-div">
                    <Global className="global-icon" />
                    <span>{item?.city}</span>
                  </div>
                )}
                {item?.description && (
                  <p className="comment-text">{item?.description}</p>
                )}
                <div className="user-info-div">
                  {!!item?.image && (
                    <div className="user-image-div">
                      <img
                        src={item?.image}
                        className="common-img"
                        alt="User"
                      />
                    </div>
                  )}
                  {item?.name && <p className="username-text">{item?.name}</p>}
                </div>
              </div>
            );
          })}
        </div>
        <div className="testimonial-slider-div">
          <button className="arrow-btn" onClick={scrollLeft}>
            <LeftArrow />
          </button>
          <button className="arrow-btn" onClick={scrollRight}>
            <RightArrow />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
